import React, { MouseEvent, Fragment, useMemo, useEffect } from 'react';
import { ApiBestPricePackageModel, ApiItem, ApiListOptions, ApiPriceType } from '@ibe/api';
import { useTheme } from '@emotion/react';
import {
  PackageItemSelection,
  ThreeColumnListItemLayout,
  StarRating,
  ItemFrame,
  useConfig,
  DateDisplay,
  useAppService,
  useMediaQuery,
  MEDIAQUERY_DEFAULTS,
  PackageParams,
  useWindow
} from '@ibe/components';
import { LoggerFactory } from '@ibe/services';
import { Col, Row } from 'reactstrap';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Keys from '../../../Translations/generated/en/bestPricePackageListItem.json.keys';
import useTranslation from '../../../Util/useTranslation';
import PackageParamsTransformer from '@/Util/ParamsTransformer/PackageParamsTransformer';
import useQuery from '@/Util/useQuery';
import IncludedServicesPreview from '@/components/IncludedServicesPreview/IncludedServicesPreview';
import ListDescription from '@/components/ListDescription';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { PriceListItemLayout } from './CustomPriceListItemLayout';
import { ListOptionsParamsTransformer } from '@/Util/ParamsTransformer/ListOptionsParamsTransformer';

type Props = {
  onSelect?: (selection: Partial<PackageItemSelection>) => void;
  item: ApiItem;
  eachItemSearchParams?: PackageParams;
};

const logger = LoggerFactory.get('PackagesPage');

const PackageListItem = (props: Props): JSX.Element => {
  const theme = useTheme();
  const config = useConfig();
  const app = useAppService();
  const format = config.displayFormatDate[app.lang] || 'DD/MM/YYYY';
  const onDetailsSelect = props.onSelect || logger.warn;
  const { t } = useTranslation('bestPricePackageListItem');
  const query = useQuery();
  const matches = useMediaQuery({ type: 'min', query: MEDIAQUERY_DEFAULTS.xl });
  const isMobileView = useMediaQuery({ type: 'max', query: MEDIAQUERY_DEFAULTS.md });
  const router = useRouter();
  const window = useWindow();

  const paramsDecoded = PackageParamsTransformer.decode(Object.fromEntries(query.entries()));
  const item = props.item as ApiBestPricePackageModel;

  const location =
    item.geoAssignment && item.geoAssignment.location ? item.geoAssignment.location : null;

  const stars = item.category ? Number(item.category.description || '0') : 0;

  const defaultRoom = item.rooms?.find(room => room.isDefault);

  const defaultMealDescription =
    defaultRoom && defaultRoom.defaultMeal ? defaultRoom.defaultMeal.description : t(Keys.roomOnly);

  const occupancy = paramsDecoded.occupancy
    ? paramsDecoded.occupancy
        .map(roomContainer => roomContainer.infants + roomContainer.adults + roomContainer.children)
        .reduce((p, c) => p + c, 0)
    : 0;
  const numberOfRooms = paramsDecoded?.occupancy?.length || 1;
  const onDetailSelect = (): void => {
    const selection = { item };
    onDetailsSelect(selection);
  };

  const url = useMemo(() => {
    if (props.eachItemSearchParams) {
      const queryParams = PackageParamsTransformer.encode({
        ...props.eachItemSearchParams,
        goToDetails: true,
        listOptions: {
          ...({} as Partial<ApiListOptions>),
          pagination: props?.eachItemSearchParams?.listOptions?.pagination
        }
      });

      return `${router.asPath.split('?')[0]}?${queryParams}`;
    }
    return '';
  }, [props.eachItemSearchParams, query.toString()]);

  const openInaNewTab = (): void => {
    if (props.eachItemSearchParams && window && url) {
      window.open(`${url}&isNewTab=true`, '_blank');
    }
  };

  const handleClick = (e: MouseEvent<HTMLSpanElement>): void => {
    e.preventDefault();
    e.stopPropagation();
    if (e.ctrlKey) {
      openInaNewTab();
    } else {
      onDetailSelect();
    }
  };

  const Center = (): JSX.Element => {
    return (
      <div className="package-list-item__center d-flex flex-column h-100">
        <div className="flex-grow-1">
          <div className="mt-3 mb-2">
            {item.listDescription ? (
              <ListDescription
                content={item.listDescription}
                showInitial={4}
                moreLabel={t(Keys.showMore)}
                lessLabel={t(Keys.showLess)}
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    );
  };

  const Wrapper = url ? Link : Fragment;

  const PackageBtn = (): JSX.Element => (
    <button
      type="button"
      className={`iso__package-item__button btn btn-secondary ${isMobileView && ' py-2 mb-3'}`}
    >
      {t(Keys.choose)}
    </button>
  );
  const TopRight = (): JSX.Element => (
    <div
      className={`package-info package-info__package w-100 d-flex justify-content-between justify-content-xl-end`}
    >
      <div className="package-info__data text-left text-xl-right">
        <IncludedServicesPreview packageModel={item} className={'d-none d-xl-flex mb-3'} />
        {!!item.originName && (
          <div>{`${t(Keys.departure)}: ${item.originName || item.origin}`}</div>
        )}
        {!!item && (
          <div className="mb-1">
            {numberOfRooms > 1
              ? `${numberOfRooms} ${t(Keys.room, { count: numberOfRooms })}, `
              : ''}
            {`${occupancy} ${t(Keys.person, { count: occupancy })}`}
          </div>
        )}
        <DateDisplay date={item.startDate} format={format} />
        {' - '}
        <DateDisplay date={item.endDate} format={format} />
        {!!item.duration && (
          <div className="mb-2">{`${item.duration.duration} ${t(Keys.nights)}`}</div>
        )}
      </div>
      <div>
        <IncludedServicesPreview packageModel={item} className={'d-flex d-xl-none mb-3'} />
        <PriceListItemLayout price={item.price} />
        <div>{!!defaultMealDescription && <span> {defaultMealDescription}</span>}</div>
      </div>
    </div>
  );

  const TopRightMobile = (): JSX.Element => (
    <Col className="px-3 my-3">
      <Col className="included_services-mobile_wrapper d-lex p-0">
        <IncludedServicesPreview packageModel={item} className={'included_services-mobile'} />
      </Col>
      <Row>
        {!!item.duration && (
          <div className="mb-1">{`${item.duration.duration} ${t(Keys.nights)}`}</div>
        )}
      </Row>
      <Row>
        {!!item && (
          <div className="mb-1">{`${occupancy} ${t(Keys.person, { count: occupancy })}`}</div>
        )}
      </Row>
      <Row>{!!defaultMealDescription && <div className="mb-1">{defaultMealDescription}</div>}</Row>
      <Row className="mb-1">
        {!!item.originName && (
          <div>{`${t(Keys.departure)}: ${item.originName || item.origin}`}</div>
        )}
      </Row>
      <Row className="mb-1">
        <DateDisplay date={item.startDate} format={format} />
        {' - '}
        <DateDisplay date={item.endDate} format={format} />
      </Row>
      <Row className="d-flex justify-content-between mt-1 ">
        <Col>
          <PriceListItemLayout price={item.price} />
        </Col>
      </Row>
    </Col>
  );

  const PackageLocation = (): JSX.Element | null =>
    location ? (
      <div className="package-list-item__location mb-3">
        <FontAwesomeIcon icon={faMapMarkerAlt} /> {location}
      </div>
    ) : null;

  const getHighlightFrame = (item: ApiBestPricePackageModel): ItemFrame | undefined => {
    const content = item?.metaInfo?.contentByMetaType?.HIGHLIGHT?.content;

    const frame: ItemFrame = {
      enableFrame: content?.enabled as boolean | undefined,
      frameText: content?.text as string | undefined,
      frameColor: content?.color as string | undefined
    };

    return frame;
  };

  const getOverlay = (): JSX.Element => {
    const tagsContent = item.metaInfo ? item.metaInfo.contentByMetaType?.TAGS?.content : undefined;
    const hotelLogoUrl = item.hotelLogo?.extraSmall?.url;
    return (
      <>
        {tagsContent && tagsContent.tags ? (
          <div className={'package-list-item__tags'}>
            {(tagsContent.tags as Array<string>).map(tag => (
              <span key={`tag-${tag}`}>{tag}</span>
            ))}
          </div>
        ) : (
          <></>
        )}
        {hotelLogoUrl ? (
          <div className={'package-list-item__hotel-logo-container'}>
            <img className={'package-list-item__hotel-logo'} src={hotelLogoUrl} />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  return (
    <Wrapper href={url} onClick={handleClick} className="text-reset text-decoration-none">
      <div className="package-list-item">
        <ThreeColumnListItemLayout
          image={item.media?.mainImage?.small}
          imageOverlay={getOverlay()}
          onClick={handleClick}
          center={<Center />}
          topRight={isMobileView ? <TopRightMobile /> : <TopRight />}
          frame={getHighlightFrame(item)}
          headline={
            <>
              <Row>
                <Col xs={'auto'}>
                  <StarRating numberOfStars={stars} />
                </Col>
                <Col>
                  <PackageLocation />
                </Col>
              </Row>
              <Row>
                <Col className="hotelName">{item.name}</Col>
              </Row>
            </>
          }
          button={<PackageBtn />}
          placeholderImage={theme.placeholderImages.package}
          fullAreaClick
          mobileViewInLg
        />
      </div>
    </Wrapper>
  );
};

export default PackageListItem;
