import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import {
  ApiDurationType,
  ApiGeoUnit,
  ApiItemType,
  ApiListOptions,
  ApiProductData,
  ApiSortOption
} from '@ibe/api';
import {
  CollapsibleRef,
  DateInputType,
  DestinationItem,
  LoadingOverlay,
  MEDIAQUERY_DEFAULTS,
  PackageDetailsSelection,
  PackageModule,
  PackageParams,
  PackageSearchStore,
  useApi,
  useClientPersistSearchParams,
  useConfig,
  useMediaQuery,
  useTheme
} from '@ibe/components';
import RenderSearch from '../render/RenderSearch';
import dayjs from 'dayjs';
import useQuery from '@/Util/useQuery';
import PackageParamsTransformer from '@/Util/ParamsTransformer/PackageParamsTransformer';
import { useRouter } from 'next/router';
import { IbeProps } from '@/components/ibe/IbeProps';
import isClient from '@/Util/globals';
import { useSearchProperties } from '@/Util/useSearchProperties';
import useRemountHooks from '@/components/ibe/useRemountHook';
import RenderSearchContent from '@/components/ibe/render/RenderSearchContent';
import RenderListItem from '@/components/ibe/render/RenderListItem';
import dynamic from 'next/dynamic';
import { useMount } from 'react-use';
import {
  CustomPackageSearchStore,
  fetchDestinationsGeoUnits,
  fetchDestinationsProducts,
  findDestinationGeoUnits,
  findDestinationProducts,
  UrlEnrichmentData
} from '@/components/ibe/IbeUtils';
import { configService } from '@/Config/config';

const CustomIndexedDestinationSelect = dynamic(
  () => import('../render/CustomIndexedDestinationSelect'),
  {
    ssr: false
  }
);

const IbeSearch = observer(function PackageSearch(props: IbeProps) {
  const isMobileView = useMediaQuery({ type: 'max', query: MEDIAQUERY_DEFAULTS.sm });
  const router = useRouter();
  const query = useQuery();
  const theme = useTheme();
  const config = useConfig();
  const api = useApi();
  const { ibeUrl, destinationsUrl, triggerSearch } = props;
  const [isLoading, setLoading] = useState(false);
  const [destinations, setDestinations] = useState<{
    geoUnits: ApiGeoUnit[];
    products: ApiProductData[];
  }>({ geoUnits: [], products: [] });

  const [urlEnrichmentData, setUrlEnrichmentData] = useState<UrlEnrichmentData | undefined>(
    undefined
  );

  const customPackageSearchStore = useLocalStore(() => new CustomPackageSearchStore(config, theme));
  customPackageSearchStore.setShowValidationErrorMessage(true);

  useEffect(() => {
    customPackageSearchStore.validateParamsAndShowError();
  }, [isMobileView]);

  const [defaultParams, setDefaultParams] = useState<Partial<PackageParams>>({
    occupancy: [
      {
        adults: Number.parseInt(props.numberOfAdults || '1'),
        children: Number.parseInt(props.numberOfChildren || '0'),
        infants: 0,
        childrenAges: [],
        infantsAges: []
      }
    ],
    startDate:
      dayjs(props.startDate).format(config.formatDate) ||
      dayjs(new Date()).add(14, 'day').format(config.formatDate),
    endDate:
      dayjs(props.endDate).format(config.formatDate) ||
      dayjs(new Date()).add(28, 'day').format(config.formatDate),
    dateInputType: DateInputType.FULL
  });

  const fetchDestination = async (onlyProduct?: boolean) => {
    const codes = [{ code: props.destination } as DestinationItem];
    const req = [
      fetchDestinationsProducts(api, codes || []),
      onlyProduct ? null : fetchDestinationsGeoUnits(api)
    ];
    return Promise.all(req);
  };

  useMount(async () => {
    if (props.destination) {
      const [productsRes, geoUnitsRes] = await fetchDestination();
      setDestinations({
        geoUnits: geoUnitsRes as ApiGeoUnit[],
        products: productsRes as ApiProductData[]
      });
      setUrlEnrichmentData({
        destinations: {
          geoUnits: geoUnitsRes as ApiGeoUnit[],
          products: productsRes as ApiProductData[]
        },
        origins: []
      });
      if (props.destination) {
        const destinationGeoUnits = findDestinationGeoUnits(
          geoUnitsRes as ApiGeoUnit[],
          props.destination
        );
        const destinationProduct = findDestinationProducts(
          productsRes as ApiProductData[],
          props.destination
        );
        if (destinationGeoUnits) {
          setDefaultParams(prevState => ({
            ...prevState,
            destinations: destinationGeoUnits
          }));
        }
        if (destinationProduct) {
          setDefaultParams(prevState => ({
            ...prevState,
            destinations: [destinationProduct]
          }));
        }
      }
    } else {
      const destinationGeoUnits = await fetchDestinationsGeoUnits(api);
      setDestinations({ geoUnits: destinationGeoUnits, products: destinations.products });
      setUrlEnrichmentData({
        destinations: {
          geoUnits: destinationGeoUnits,
          products: destinations.products
        },
        origins: []
      });
    }
  });

  const { setSearchParams } =
    useClientPersistSearchParams<Partial<PackageParams>>(isClient() ? window || null : null) || {};
  const [prefillOnly, setPrefillOnly] = useState(false);
  const { setSearchProperties: setGlobalSearchProperties } = useSearchProperties();

  const searchParams = useMemo(() => {
    const paramsDecoded = PackageParamsTransformer.decode(Object.fromEntries(query.entries()));
    paramsDecoded.dateInputType = DateInputType.FULL;

    if (query.get('destinationCode')) {
      const foundDes = findDestinationGeoUnits(
        destinations.geoUnits,
        query.get('destinationCode') || ''
      );

      const foundProd = findDestinationProducts(
        destinations.products,
        query.get('destinationCode') || ''
      );
      paramsDecoded.destinations = foundProd ? [foundProd] : foundDes;
    }

    query.delete('cmspath');
    const searchParameters =
      Object.keys(Object.fromEntries(query.entries())).length > 0
        ? {
            ...paramsDecoded
          }
        : { ...defaultParams };
    return searchParameters;
  }, [query, destinations]);

  const onSelect = (params: PackageDetailsSelection): void => {};

  const updateUrlParams = (params: PackageParams, listOptions: ApiListOptions): void => {
    setLoading(true);
    const queryParams = PackageParamsTransformer.encode(params);
    let url = `${ibeUrl}?${queryParams}`;

    if (params.destinations) {
      const destinations_count = destinations.geoUnits.reduce(
        (count, current) => count + 1 + Object.keys(current.children).length,
        0
      );
      const isSearchForEveryDestination =
        params.destinations.filter(value => value.code).length === destinations_count;

      if (isSearchForEveryDestination && destinationsUrl) {
        setLoading(true);
        const queryParams = PackageParamsTransformer.encode(params);
        url = `${destinationsUrl}?${queryParams}`;
      }
    }

    if (!triggerSearch) {
      setPrefillOnly(false);
    }
    if (!!setSearchParams) {
      setSearchParams(router.asPath.split('?')[0], params);
    }
    if (ibeUrl) {
      router.push(url);
    }
  };

  const updateUrlParamsForSearch = (params: PackageParams, listOptions: ApiListOptions): void => {
    if (params.isFromSearchButton === true) {
      params.goToDetails = true;
    }
    updateUrlParams(params, listOptions);
  };

  const onPageChangeListener = useCallback(() => {
    const findSearchContainer = document.getElementsByClassName('iso__serviceList__mobile-section');
    if (window && findSearchContainer[0]) {
      const y = (findSearchContainer[0]?.getBoundingClientRect().top || 0) + window.scrollY;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, []);

  function renderSearchWithMessage(
    CategorySelect: ReactNode,
    OriginSelect: ReactNode,
    DestinationSelect: ReactNode,
    TravelDatePicker: ReactNode,
    TravelMonthPicker: ReactNode,
    TravelerPicker: ReactNode,
    SearchButton: ReactNode,
    OriginCheckboxes: ReactNode,
    DateRangePickerSingleSelect: ReactNode,
    collapsibleRef?: CollapsibleRef
  ) {
    return RenderSearch(
      CategorySelect,
      OriginSelect,
      DestinationSelect,
      TravelDatePicker,
      TravelMonthPicker,
      TravelerPicker,
      SearchButton,
      OriginCheckboxes,
      DateRangePickerSingleSelect,
      collapsibleRef,
      undefined,
      customPackageSearchStore
    );
  }

  const [keys] = useRemountHooks();
  return (
    <LoadingOverlay isLoading={isLoading}>
      <div className={'ibe-search-component'}>
        <PackageModule
          customPackageSearchStore={customPackageSearchStore}
          onSelect={onSelect}
          onSearch={updateUrlParamsForSearch}
          onDetail={updateUrlParams}
          searchParams={prefillOnly ? undefined : searchParams}
          showLabels
          subType={ApiItemType.PREDEFINEDPACKAGE}
          showMultipleDurations={false}
          showTotalPriceOnly
          // destinationMandatory //
          endDateMandatory
          withDateRangePicker
          withAdvancedDateRangePicker
          withGroupRequestForm={false}
          withRoomCountSelector={configService.get().enableMultipleRooms}
          renderSearch={renderSearchWithMessage}
          renderSearchContent={RenderSearchContent}
          sortingType={{
            GROUP: [],
            SINGLE: [ApiSortOption.NAME, ApiSortOption.PRICE]
          }}
          sorting={[ApiSortOption.NAME, ApiSortOption.PRICE]}
          minDateRangeSpan={2}
          maxDateRangeSpan={200}
          renderListItem={RenderListItem}
          minSelectableChildAge={0}
          noDatePickerOverflow
          selectLabelSort
          withChildrenSelection
          serviceSearchWithChildAgePicker
          changeOnBlur
          widgetClickable
          packageOriginMustBeSet={false}
          packageOriginWithRemoveAll
          packageOriginWithSelectAll
          customIndexedDestinationSelect={(store: PackageSearchStore) => (
            <CustomIndexedDestinationSelect store={store} urlEnrichmentData={urlEnrichmentData} />
          )}
          dateRangeDurationProps={{
            withDuration: true,
            value: { duration: 7, type: ApiDurationType.DAY },
            usePlaceholderAsOption: false
          }}
          dateRangeUpdateStateFromProps={false}
          dateRangeFillWithLastDateRangeWhenNoSelection
          dateRangeClearSelectionsOnOpen
          doBestPriceSearch
          bestPriceMaxDateRangeSpan={42}
          bestPriceSubmitOnChange
          hideSearchInDetails
          paginationProps={{
            onPageChangeListener
          }}
          key={keys[0]}
          blockInitialSearch
          showPaxSelectionInModal={isMobileView}
          showDateSelectionInModal={isMobileView}
          showSearchParamsPerItem
          paxSelectionRevalidateChildrenOnChangeAfterError
          paxSelectionValidateChildrenPaxSelectionOnLeave
        />
      </div>
    </LoadingOverlay>
  );
});

export default IbeSearch;
