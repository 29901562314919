/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-09-16 15:42:34 */

const Keys = {
  to: 'to',
  nights: 'nights',
  night: 'night',
  night_plural: 'night_plural',
  days: 'days',
  perAdult: 'perAdult',
  priceInfo: 'priceInfo',
  totalFrom: 'totalFrom',
  about: 'about',
  inclusions: 'inclusions',
  itinerary: 'itinerary',
  important_info: 'important_info',
  alternative_dates: 'alternative_dates',
  chosen_departure: 'chosen_departure',
  of: 'of',
  view_all: 'view_all',
  show_more: 'show_more',
  show_less: 'show_less',
  day: 'day',
  continue: 'continue',
  prev: 'prev',
  next: 'next',
  previewExample: 'previewExample',
  selectOptions: 'selectOptions',
  BACK_TO_LIST: 'BACK_TO_LIST',
  yourHotel: 'yourHotel',
  perPerson: 'perPerson',
  datesAndPrices: 'datesAndPrices',
  from: 'from',
  meals: 'meals',
  roomOnly: 'roomOnly',
  with: 'with',
  flightFrom: 'flightFrom',
  overview: 'overview',
  location: 'location',
  accommodation: 'accommodation',
  includedServices: 'includedServices',
  print: 'print',
  totalPrice: 'totalPrice',
  person: 'person',
  person_plural: 'person_plural',
  dateAndPrices: 'dateAndPrices',
  details: 'details',
  insurance: 'insurance',
  transfers: 'transfers',
  insuranceIncluded: 'insuranceIncluded',
  transferIncluded: 'transferIncluded',
  adult: 'adult',
  adult_plural: 'adult_plural',
  child: 'child',
  child_plural: 'child_plural',
  included: 'included',
  outboundFlight: 'outboundFlight',
  returnFLight: 'returnFLight',
  voucher: 'voucher',
  inboundOptionalLuggage: 'inboundOptionalLuggage',
  outboundOptionalLuggage: 'outboundOptionalLuggage',
  inboundIncludedLuggage: 'inboundIncludedLuggage',
  outboundIncludedLuggage: 'outboundIncludedLuggage',
  kilo: 'kilo',
  piece: 'piece',
  freeBaggageIncluded_plural: 'freeBaggageIncluded_plural',
  freeBaggageIncluded: 'freeBaggageIncluded',
  accommodationCategoryA: 'accommodationCategoryA',
  accommodationCategoryB: 'accommodationCategoryB',
  accommodationCategoryH: 'accommodationCategoryH',
  percentagePriceInsuranceLabel: 'percentagePriceInsuranceLabel',
  room: 'room',
  room_plural: 'room_plural'
}; export default Keys;